import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import mainImage from '../images/main-graphic@2x.png'
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="row main-content" id="mainContent">
            <div className="row main-header">
            <div className="main-header__logo">
            <h1><span>Kat</span>erina</h1> 
            <h1><span>Metax</span>opoulou</h1>
            <h2>Web Developer</h2>
            </div>
            
            <img src={mainImage} alt="Laptop and mobile" className="main-header__img" />
            </div>
            
            <div className="row secondary-section">
            <div className="six columns portfolio-link">
            <div className="row portfolio-link__header"><h2>Want to see my work?</h2></div>
            <div className="row portfolio-link__button-section">
            <Link to="portfolio"><button className="portfolio-link__button">See my work</button></Link>
            </div>
            </div>
            
            <div className="six columns focus-section">
            <h2>ABOUT</h2>

            <p>My name is Katerina Metaxopoulou but most people call me Kat for short.</p>
            
            <p>I am a web developer with a passion for creating websites that are user friendly. My journey into web development started when I was 14 years old when I made my first website and since then I knew it was my passion!</p>

            <p><Link to="about">See more...</Link></p>
            
            </div>
            </div>
            </div>
  </Layout>
)

export default IndexPage
